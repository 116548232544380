import React from "react"
import {Helmet} from "react-helmet"
import Layout from "../../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
//import Row from 'react-bootstrap/Row'
//import Col from 'react-bootstrap/Col'
import "../../styles/styles.scss"
import aboutStyles from "./about.module.scss"
import Logo from "../../components/logo"
import "intersection-observer"
import federationImg from "../../images/onas/tabliczka.jpg"
import leftCorner from "../../images/decorative-corner-left.svg"
//Lightbox//
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import ConcatedOptions from "../../components/lightbox";
import "../../components/lightbox.module.scss";

const AboutPage = () => (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pl" />
        <title>O Nas | Pensjonat U Wierzby Pod Lasem</title>
        <meta name="description" content="Zapraszamy do zapoznania się z historią Pensjonatu U Wierzby Pod Lasem."/>
        <link rel="canonical" href="https://www.uwierzby.pl/o-nas/"/>
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link rel="preload" href="https://fonts.googleapis.com/css2?family=Charm&family=Cormorant+SC:wght@300&family=Quicksand&display=swap" as="style"></link>
      </Helmet>
      <Layout>
        <section className={`JumbotronSection ${aboutStyles.JumbotronSection___about}`}>
          <Jumbotron fluid className={`Jumbotron`}>
            <div className={`Jumbotron_overlay`}></div>
            <div className={`Jumbotron_logo`}>
              <Logo/>
            </div>
            <Container fluid className={`Jumbotron_container`}>
              <h1 className="animate__animated animate__slideInLeft">o nas</h1>
              <h3 className={`SectionSubTitleH3 light animate__animated animate__slideInRight`}>ponad 200 lat tradycji</h3>
            </Container>
          </Jumbotron>
        </section>
        <section className={`BreadcrumbSection`}>
          <Breadcrumb className={`Breadcrumb`}>
              <Breadcrumb.Item className={`Breadcrumb_item`} href="../">Strona Główna</Breadcrumb.Item>
              <Breadcrumb.Item className={`Breadcrumb_item`} active>O Nas</Breadcrumb.Item>
          </Breadcrumb>
        </section>
        <section className={`MainSection`}>
        <Container fluid className={`MainSection_wrapper`}>
          <img className={`MainSection_corner___left`} src={leftCorner} alt="top left decorative corner"></img>
          <div className={`MainSection_textWrapper`}>
            <div className={`SectionTitle_wrapper`}>
              <div className={`SectionTitle_bigLetter`}><p>O</p></div>
              <h1 className={`SectionTitle`}>o nas</h1>
              <h3 className={`SectionSubTitleH3`}>pensjonat z duszą</h3>
            </div>
            <div className={`MainSection_main`}>
              <div className={`MainSection_mainText limited`}>
                <p>We wsi <span>Przyłęk</span> znajduje się urokliwe siedlisko powstałe w <span>1804</span> roku, co udokumentowano na drewnianej belce pod sufitem wyrytą datą.</p>
                <p>W związku z tym jest to jedno z najstarszych domostw powstałych w Przyłęku.</p>
                <p>Dom ten kupili i odnowili J.B. Wierzbińscy - mieszkańcy Nowego Tomyśla, których korzenie pochodzą z Przyłęku.</p>
                <p>Wykorzystując urok domu i jego położenie wśród lasów i pól stworzyli w 2000 roku <span>Gospodarstwo Agroturystyczne - "U Wierzby Pod Lasem"</span>, w którym gościło już bardzo wielu gości z kraju i zagranicy.</p>
              </div>
              <div className={`SectionTitle_wrapper`}>
                <div className={`SectionTitle_bigLetter`}><p>S</p></div>
                <h2 className={`SectionSubTitleH2`}>sprawdzony obiekt</h2>
                <h3 className={`SectionSubTitleH3`}>w polskiej federacji turystyki wiejskiej</h3>
              </div>
              <div className={`MainSection_mainText limited`}>
                <p>Jesteśmy sprawdzonym, godnym zaufania obiektem. Należymy do <br/><span>Polskiej Federecji Turystyki Wiejskiej</span></p>
              </div>
              <div className={`MainSection_mainImg`}>
                <img src={federationImg} alt="tablica poskiej fedracji turystyki wiejskiej"/>
              </div>
              <div className={`SectionTitle_wrapper`}>
                <div className={`SectionTitle_bigLetter`}><p>P</p></div>
                <h2 className={`SectionSubTitleH2`}>perfekcyjne położenie</h2>
                <h3 className={`SectionSubTitleH3`}>sielskie krajobrazy</h3>
              </div>
              <div className={`MainSection_mainText limited`}>
                <p>Jesteśmy idealnym miejscem dla tych, którzy naprawdę pragną odpocząć oraz cenią sobie <strong>ciszę i spokój w bliskości z naturą</strong>.</p>
                <p>Wokół domu roztacza się malowniczy krajobraz ogrodu, w którym znajduje się miejsce na ognisko, boisko do gier sportowych, plac zabaw dla najmłodszych, latem basen do pływania, punkt widokowy, staw oraz wiele zakątków, w których można spokojnie wypocząć.</p>
              </div>
            </div>
          </div>
          <SimpleReactLightbox>
            <div className={`${aboutStyles.AboutSection_fotoGrid}`}>
              <SRLWrapper options={ConcatedOptions}>
                    <a  className={`${aboutStyles.FotoGrid_item} ${aboutStyles.FotoGrid_item___a}`}
                      href={require('../../images/onas/staw-krzeslo.jpg')} data-attribute="SRL">
                      <img className={aboutStyles.FotoGrid_itemImg} src={require("../../images/onas/staw-krzeslo.jpg")} alt="zaciszny kącik nad stawem"></img>
                    </a>
                    <a className={`${aboutStyles.FotoGrid_item} ${aboutStyles.FotoGrid_item___b}`}
                        href={require('../../images/onas/mostek-przejscie.jpg')} data-attribute="SRL">
                        <img className={aboutStyles.FotoGrid_itemImg} src={require("../../images/onas/mostek-przejscie.jpg")} alt="mostek obok stawu"></img>
                    </a>
                    <a className={`${aboutStyles.FotoGrid_item} ${aboutStyles.FotoGrid_item___c}`} 
                        href={require('../../images/onas/ogrod-wypoczynek.jpg')} data-attribute="SRL">
                        <img className={aboutStyles.FotoGrid_itemImg} src={require("../../images/onas/ogrod-wypoczynek.jpg")} alt="wypoczynek w ogrodzie"></img>
                    </a>
                    <a className={`${aboutStyles.FotoGrid_item} ${aboutStyles.FotoGrid_item___d}`} 
                        href={require('../../images/onas/basen-lisc.jpg')} data-attribute="SRL">
                        <img className={aboutStyles.FotoGrid_itemImg} src={require("../../images/onas/basen-lisc.jpg")} alt="basen pośród zieleni"></img>
                    </a>
                    <a className={`${aboutStyles.FotoGrid_item} ${aboutStyles.FotoGrid_item___e}`} 
                        href={require('../../images/onas/lezaki-basen.jpg')} data-attribute="SRL">
                        <img className={aboutStyles.FotoGrid_itemImg} src={require("../../images/onas/lezaki-basen.jpg")} alt="leżaki przy basenie"></img>
                    </a>  
              </SRLWrapper>
            </div>
          </SimpleReactLightbox>
          <div className={`MainSection_main`}>
            <div className={`MainSection_mainText limited`}>
                <p className={aboutStyles.aboutText_invitation}><span>Pensjonat "U Wierzby Pod Lasem" serdecznie zaprasza przez cały rok w swe gościnne progi.</span></p>
                <p>A jeśli byli już Państwo naszymi Gośćmi, to prosimy o pozostawienie opinii pod naszą wizytówką w Google i zapraszamy do nas ponownie!</p>
            </div>
          </div>
          </Container>
        </section>
      </Layout>
  </>
);

export default AboutPage
